<template>
  <img
    :src="image && image.sizes
              && image.sizes.large"
    :srcset="image && image.sizes
              && (srcSet)"
    :sizes="image && image.sizes
              && (sizes)"
    :alt="image && image.alt"
    :title="image && image.alt"
    :width="widthUpdated"
    :height="heightUpdated"
  />
</template>
<script>
import screenWidth from '@/enums/screenWidth';
import wpImageSizeCollection from '@/enums/wpImageSizeCollection';
import wpImageSizeCollectionType from '@/enums/wpImageSizeCollectionType';
import wpImageSize from '@/enums/wpImageSize';

export default {
  props: {
    image: {
      type: Object,
      default: () => {},
    },
    SizeCollectionType: {
      type: String,
      default: wpImageSizeCollectionType.MEDIUM,
    },
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  computed: {
    imageSizes() {
      return wpImageSizeCollection[this.SizeCollectionType];
    },
    size() {
      const lastIndex = wpImageSizeCollection[this.SizeCollectionType].length - 1;
      return wpImageSizeCollection[this.SizeCollectionType][lastIndex];
    },
    widthUpdated() {
      return Object.keys(this.image).includes('sizes')
        ? this.image.sizes[`${this.size}-width`] : this.width;
    },
    heightUpdated() {
      return Object.keys(this.image).includes('sizes')
        ? this.image.sizes[`${this.size}-width`] : this.height;
    },
    srcSet() {
      let res = '';

      if (this.imageSizes.includes(wpImageSize.LOGO)) {
        res += `${this.image.sizes.logos} ${this.image.sizes['logos-width']}w`;
      }
      if (this.imageSizes.includes(wpImageSize.THUMB_BIGGER)) {
        if (res !== '') res += ', ';
        res += `${this.image.sizes.thumb_bigger} ${this.image.sizes['thumb_bigger-width']}w`;
      }
      if (this.imageSizes.includes(wpImageSize.SMALL)) {
        if (res !== '') res += ', ';
        res += `${this.image.sizes.small} ${this.image.sizes['small-width']}w`;
      }
      if (this.imageSizes.includes(wpImageSize.MEDIUM)) {
        if (res !== '') res += ', ';
        res += `${this.image.sizes.medium} ${this.image.sizes['medium-width']}w`;
      }
      if (this.imageSizes.includes(wpImageSize.MEDIUM_LARGE)) {
        if (res !== '') res += ', ';
        res += `${this.image.sizes.c_medium_large} ${this.image.sizes['c_medium_large-width']}w`;
      }
      if (this.imageSizes.includes(wpImageSize.LARGE)) {
        if (res !== '') res += ', ';
        res += `${this.image.sizes.large} ${this.image.sizes['large-width']}w`;
      }
      if (this.imageSizes.includes(wpImageSize.EXTRA_LARGE)) {
        if (res !== '') res += ', ';
        res += `${this.image.sizes[wpImageSize.EXTRA_LARGE]} ${this.image.sizes[
          `${wpImageSize.EXTRA_LARGE}-width`]}w`;
      }
      return res;
    },
    sizes() {
      const screenWidthCollection = [
        screenWidth.MEDIUM, screenWidth.MEDIUM_LARGE, screenWidth.LARGE];
      let res = '';
      this.imageSizes.forEach((element, index) => {
        if (index === 0) {
          res = `${this.image.sizes[`${element}-width`]}px`;
        } else {
          res = `(min-width: ${screenWidthCollection[index - 1]}px) ${this.image.sizes[`${element}-width`]}px, ${res}`;
        }
      });
      return res;
    },
  },
};
</script>
